import React, { memo } from 'react'
import styled from 'styled-components'
import { IconButton, Button as GButton } from '@material-ui/core'

import { setProp } from 'libs/string'
import { colors, spaces } from 'libs/theme'
import { Grid, Text } from 'components/elements'

const StyledButtonIcon = styled(IconButton)`
  ${({ sizes, padding, color }) => `
    ${setProp('width', sizes)}
    ${setProp('height', sizes)}
    ${setProp('padding', padding)}
    ${setProp('background-color', color)}
  `}
`

/*
ButtonIcon = {
  size: string;
  onClick: () => void;
  padding?: string;
  color?: string;
};
*/
export const ButtonIcon = memo(({
  children,
  size = '44px', padding = '6px', color, onClick
}) => {
  return (
    <StyledButtonIcon sizes={size} padding={padding} color={color} onClick={onClick}>
      {children}
    </StyledButtonIcon>
  )
}, () => {
  return true
})

const StyledButton = styled(GButton)`
  text-transform: inherit;
  ${({ width, minWidth, height, minHeight, padding, colorr, bordercolor, fontcolor, radius, align, alignitems, justifycontent, overflow, hover }) => `
    ${setProp('overflow', overflow)}
    ${setProp('width', width)}
    ${setProp('min-width', minWidth)}
    ${setProp('height', height)}
    ${setProp('min-height', minHeight)}
    ${setProp('padding', padding)}
    ${setProp('background-color', colorr)}
    ${setProp('border', bordercolor ? `1px solid ${bordercolor}` : undefined)}
    ${setProp('border-radius', radius)}
    ${setProp('text-align', align)}
    ${setProp('color', fontcolor)}
    ${setProp('align-items', alignitems)}
    ${setProp('justify-content', justifycontent)}
    &:hover {
      ${setProp('background-color', colorr || hover?.color)}
      ${
          !!hover ? `
          ${setProp('border', hover.border)}
          ${setProp('color', hover.fontColor)}   
        ` : ''
      }
    }
  `}
`

/**
 *** button component ***
 *
 * children: ReactNode
 * variant: flat | outline | text
 * disabled: boolean
 * disableRipple: boolean
 * overflow: string, default - hidden
 * width: string
 * height: string
 * minWidth: string
 * minHeight: string
 * align: string, text-align
 * alignItems: string, align-items
 * justifyContent: string, justify-content
 * padding: string
 * color: hash color, default - white
 * fontColor: hash color, default - g800
 * backgroundColor: hash color
 * radius: string, border-radius
 * hover: 마우스 hover 시 { border: string, color: hash color }
 * target
 * style
 * onClick
**/
export const Button = memo(({
  children, variant = 'flat', disabled, disableRipple, overflow = 'hidden',
  width, height, minWidth, minHeight, align = 'center', alignItems, justifyContent, padding,
  color = colors.white, fontColor = colors.grey.g800, backgroundColor,
  radius = spaces.radius.medium.px, hover,
  target, style, onClick, role, testId
}) => {
  return (
    <StyledButton target={target} underline='none' hover={hover} disabled={disabled} disableRipple={disableRipple} overflow={overflow} data-testid={testId}
                  width={width} height={height} minwidth={minWidth} minheight={minHeight} align={align} alignitems={alignItems} justifycontent={justifyContent}
                  padding={padding} colorr={variant === 'flat' ? color : backgroundColor || colors.transparent}
                  bordercolor={variant === 'text' ? undefined : color} fontcolor={fontColor} radius={radius}
                  style={style} onClick={onClick} role={role}>
      {children}
    </StyledButton>
  )
}, (p, n) => {
  if (p.children !== n.children) return false
  if (p.onClick !== n.onClick) return false
  return true
})

/**
 *** tab component ***
 *
 * color: hash color, colors.primary.normal
 * items = [{ key: string, name: string }]
 * value: (key: string)
 * setValue: (key: string) => void
 * borderHeight: string, border-width, 2px
 * fontSize: string, font-size, 17px
 * hPadding: string, 가로 padding, spaces.default.px
 * topPadding: string, 상단 padding, spaces.component.s2.px
 * bottomPadding: string, 하단 padding, spaces.component.s2.px
 * gap: string, spaces.contents.s1.px
 * height: number, 50
 * isFit: boolean => width를 100%로 셋팅 후 아이템이 n등분, false
**/
export const Tab = memo(({
  color = colors.primary.normal, items, value, setValue, borderHeight = '2px', fontSize = '17px',
  hPadding = spaces.default.px, topPadding = spaces.component.s2.px, bottomPadding = spaces.component.s2.px, gap = spaces.contents.s1.px,
  height = 50, isFit = false
}) => {
  return (
    <Grid width='100%' overflow='hidden' position='relative'>
      <Grid overflow='auto' padding={`${topPadding} 0 ${bottomPadding} 0`} display='flex'>
        {
          !isFit && <Grid width={hPadding} style={{ flexShrink: 0 }} />
        }
        <Grid display='flex' height={`${height + 2}px`} gap={isFit ? undefined : gap} width={isFit ? '100%' : undefined} style={{ flexShrink: 0 }}>
          {
            items && items.map(item => (
              <Button key={item.key} padding='0' variant='text' radius='0' style={{ flexShrink: 0, flex: isFit ? 1 : 'auto' }}
                      onClick={() => setValue(item.key)}>
                <Grid overflow='auto' height={`${height}px`} rows='1fr 3px' align='space-between'>
                  <Grid align='center' rows='1fr'>
                    <Text size={fontSize} weight={value === item.key ? '500' : '400'} isBreak={false}
                          color={value === item.key ? color : colors.grey.g400} style={{ wordBreak: 'keep-all' }}>
                      {item.name}
                    </Text>
                  </Grid>
                  <Grid position='relative' width='100%' color={value === item.key ? color : colors.transparent} height={borderHeight} borderRadius='1px' style={{ zIndex: 1 }} />
                </Grid>
              </Button>
            ))
          }
        </Grid>
        {
          !isFit && <Grid width={hPadding} style={{ flexShrink: 0 }} />
        }
      </Grid>
    </Grid>
  )
}, (p, n) => {
  if (p.value !== n.value) return false
  if (p.items !== n.items) return false
  return true
})

/*
Tag = {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  variant?: string;
  padding?: string;
  borderRadius?: string;
  minWidth?: string;
  style?: string;
  children?: ReactNode;
};
*/
export const Tag = memo(({
  backgroundColor = colors.white, color = colors.grey.g700, borderColor = colors.transparent,
  variant = 'label2', padding = `${spaces.component.s3.px} ${spaces.component.s4.px}`,
  borderRadius = spaces.radius.tag.px, minWidth, style,
  children
}) => {
  return (
    <Grid isInline padding={padding} minWidth={minWidth}
          color={backgroundColor} borderRadius={borderRadius}
          borderColor={borderColor} borderStyle='solid' borderWidth='1px' style={style}>
      <Text color={color} variant={variant} align='center'>
        {children}
      </Text>
    </Grid>
  )
}, (p, n) => {
  if (p.backgroundColor !== n.backgroundColor) return false
  if (p.children !== n.children) return false
  return true
})
