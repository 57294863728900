import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import TagManager from 'react-gtm-module'

import { getLocalItem, setLocalItem } from './local'
import { decodeJwt } from './string'
import { setAlert, setIsLoading, setUser } from 'store/default'

const NATIVE_RESPONSE_TIMEOUT = 2000 // 2 seconds
const NATIVE_RESPONSE_TIMEOUT_ERROR_MSG = 'Native response timeout elapsed'

const ACCESS_TOKEN_KEY = 'token'
const REFRESH_TOKEN_KEY = 'refresh_token'

const pathSet = {
  signupEmail: '/api/auth/email/signup/',
  verify: '/api/auth/email/verify/',
  refresh: '/api/auth/token/refresh/',
  sendVerify: '/api/auth/email/verify/send/',
  signupSocial: '/api/auth/social/signup/',
  loginEmail: '/api/auth/email/login/',
  loginSns: '/api/auth/social/login/',
  findPassword: '/api/auth/password/reset/',
  resetPassword: '/api/auth/password/reset/confirm/',
  changePassword: '/api/auth/password/change/',
  integration: '/api/auth/integration/',
  unregister: '/api/auth/unregister/',
  nice: '/api/nice/',
  me: '/api/users/me/',
  identify: '/api/users/me/identify/',
  'identity-verified': '/api/users/identity-verified/',
  card: '/api/users/me/cards/',
  events: '/api/users/me/events/',
  notifications: '/api/users/me/notifications/',
  qustions: '/api/users/me/questionnaire/',
  myCommunity: '/api/posts/me/',
  getNotice: '/api/announcements/',
  journal: '/api/v1/journals/',
  journalCategories: '/api/journals/categories/',
  counsel: '/api/posts/',
  clinics: '/api/clinics/',
  prices: '/api/clinics/prices/',
  doctor: '/api/doctors/',
  banners: '/api/banners/',
  medicine: '/api/medicine/',
  ingredients: '/api/medicine/ingredients',
  topBanner: '/api/topBanner/',
  holidays: '/api/holidays/',
  kakaoAuth: '/oauth/token',
  treatments: '/api/v1/treatments/',
  hospital: '/api/v1/hospitals/',
  event: '/api/v1/consulting-requests/',
  questionnaires: '/api/v1/questionnaires/',
  deliveryCompanies: '/api/deliveryCompanies/',
  'me-v1': '/api/v1/users/me/',
  'me-integrated': '/api/v1/users/me/integrated/',
  'doctor-v1': '/api/v1/doctors/',
  myRoutine: '/api/v1/user-routine-associations/me/',
  myProgram: '/api/v1/user-program-associations/me/',
  weekAchieveRate: '/api/v1/user-routine-associations/me/daily-achievement-rates/',
  achieveRatePhrase: '/api/v1/routines/me/achievement/phrase',
  achieveRoutine: '/api/v1/user-routine-achievements/me/',
  failRoutine: '/api/v1/user-routine-achievements/me/',
  removeRoutine: '/api/v1/user-routine-associations/me/',
  removeProgram: '/api/v1/user-program-associations/me/',
  endRoutine: '/api/v1/user-routine-associations/me/',
  endProgram: '/api/v1/user-program-associations/me/',
  routine: '/api/v1/routines/',
  program: '/api/v1/programs/',
  addProgram: '/api/v1/user-program-associations/me',
  addRoutine: '/api/v1/user-routine-associations/me',
  participatingRoutineProgram: '/api/v1/user-routine-associations/me/current-participating/',
  updateRoutine: '/api/v1/user-routine-associations/me/',
  achievedRoutine: '/api/v1/user-routine-achievements/me/'
}
const createParameter = variable => {
  if (!variable || (variable && Object.keys(variable).length === 0)) return ''
  const queries = []
  Object.keys(variable).forEach(key => {
    if (/^[\w-\]+[[]+[\w-\]+[]]/.test(key)) {
      queries.push(`${variable[key].map(s => `${key}=${s}`).join('&')}`)
    } else {
      if (Array.isArray(variable[key])) variable[key].map(v => queries.push(`${key}=${v}`))
      else queries.push(`${key}=${variable[key]}`)
      // queries.push(`${key}=${variable[key]}`)
    }
  })
  return '?' + queries.join('&')
}

const getRandomInt = max => {
  max = max || Number.MAX_SAFE_INTEGER
  return Math.floor(Math.random() * max)
}

class Api {
  axiosInstance = undefined
  paymentTimer = 0
  paymentLimit = 3
  dispatch = undefined

  constructor (baseURL, dispatch) {
    this.dispatch = dispatch
    this.axiosAbortController = new AbortController()
    const axiosInstance = axios.create({
      signal: this.axiosAbortController.signal,
      baseURL,
      timeout: 60000, // 60 secs
      maxNRetries: 3,
      retryInitInterval: 1000, // 1 sec
      retryJitter: 2000, // 2 secs
      retryCount: 0
    })
    axiosInstance.interceptors.response.use(function (response) {
      return response
    }, async (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }
      const config = error.config
      if (!config || !config.maxNRetries) {
        return Promise.reject(error)
      }

      const request = error.request
      const response = error.response
      if (response) {
        if (response.status === 401 &&
            !config.preventAuthRetry // 로그인 API 호출시 서버는 401 응답코드를 반환할 수 있음. 이 경우에는 따로 access-token 을 갱신하고 재시도 하지 않음.
        ) {
          if (config.isRefreshAuthTokenRequest) {
            return Promise.reject(error)
          } else {
            try {
              const authTokens = await this.refreshAuthTokens()
              config.headers.authorization = 'Bearer ' + authTokens.accessToken
              return new Promise((resolve) => {
                resolve(axiosInstance(config))
              })
            } catch (refreshTokenError) {
              if (!axios.isCancel(refreshTokenError)) {
                if (refreshTokenError.response && (refreshTokenError.response.status === 400 || refreshTokenError.response.status === 401)) {
                  // 토큰 갱신 요청이 refresh-token 의 만료로 인해 거부되는 경우.
                  // 캐싱된 유저 로그인 정보를 만료 처리.
                  // **주의**: '_logout()' 내 유저 인증을 요하는 API 요청이 있으면(그리하여 refreshTokenError로 이어지는 경우) 무한 루프에 빠질 수 있음. **
                  await this._logout()

                  let redirectToLogin = false

                  if (!config.preventRedirectToLogin) {
                    // 로그인 화면으로 이동.
                    if (!window.location.pathname.match(/(\/*)account(\/*)/i)) {
                      this.axiosAbortController.abort()
                      window.location.href = `/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`
                      redirectToLogin = true
                    }
                  }
                }
              }
              return Promise.reject(error) // refreshTokenError 가 아닌 본(origin) 요청에 대한 에러(401 Unauthorized)를 throw.
            }
          }
        } else {
          return Promise.reject(error)
        }
      } else {
        // 네트워크 오류
        if (config.retryCount >= config.maxNRetries) {
          if (confirm('서버와의 연결이 불안정합니다. 요청을 재전송하시겠습니까?')) {
            config.retryCount = 0
          } else {
            return Promise.reject(error)
          }
        }

        const exponentialBackoffInterval = Math.max(
          config.retryInitInterval,
          config.retryInitInterval * Math.pow(2, config.retryCount) +
          (Math.random() * config.retryJitter * 2 - config.retryJitter)
        )

        config.retryCount += 1

        return new Promise((resolve) => {
          setTimeout(() => resolve(axiosInstance(config)), exponentialBackoffInterval)
        })
      }
    })

    this.axiosInstance = axiosInstance
  }

  alertError = (error) => {
    // error.response.data.detail 에 값(에러 메시지)이 존재하는 경우,
    // 해당 값을 사용자 화면에 표시.
    const response = error.response
    if (response) {
      if (response.data?.detail) {
        // TODO: 현재 'window.alert()' 또는 'window.confirm()' 과 같이, 진행을 막는 (block) 기능은 제공하고 있지 않음.
        //       때문에 완전한 모달(modal) 로서의 기능을 하지 못함. 향후 이에 대한 개선이 필요.
        const errorMsg = response.data.detail
        if (this.dispatch) {
          this.dispatch(setAlert({ body: errorMsg }))
        } else {
          alert(errorMsg)
        }
      }
    }
  }

  setFcm = async () => {
    const fcmToken = getLocalItem('fcmToken')
    if (fcmToken) {
      await this.request('put', 'me', {
        fcm_registration_id: fcmToken
      }, {
        path: 'fcm',
        preventErrorAlert: true,
        preventRedirectToLogin: true
      })
    }
  }

  resendEmail = async (email, router) => {
    const { success } = await window.api.request('post', 'sendVerify', { email })
    if (success) {
      router.push(`/account/email?email=${email}`)
    }
  }

  checkSaveLogin = (userId, refreshToken) => {
    const isSave = getLocalItem('is_save_login_time')
    if (isSave && isSave.now * 1000 * 60 * 5 > new Date().getTime()) {
      setLocalItem(REFRESH_TOKEN_KEY, refreshToken)
      setLocalItem('is_save_login_time', undefined)
    }
  }

  getAccessToken = async () => {
    let promise = new Promise((resolve, reject) => {
      resolve(getLocalItem(ACCESS_TOKEN_KEY))
    })

    if (window.webViewNativeInterface && window.webViewNativeInterface.NATIVE_getAccessToken) {
      const callbackSuffix = new Date().getTime() + '_' + getRandomInt()
      const successCallbackId = 'NATIVE_getAccessToken_successCallback_' + callbackSuffix
      const errorCallbackId = 'NATIVE_getAccessToken_errorCallback_' + callbackSuffix

      const timeout = setTimeout(() => {
        if (window[errorCallbackId]) {
          window[errorCallbackId](new Error(NATIVE_RESPONSE_TIMEOUT_ERROR_MSG))
        }
      }, NATIVE_RESPONSE_TIMEOUT)

      promise = new Promise((resolve, reject) => {
        window[successCallbackId] = resolve
        window[errorCallbackId] = reject
      })
      window.webViewNativeInterface.NATIVE_getAccessToken(successCallbackId, errorCallbackId)
    }

    return promise
  }

  getRefreshToken = async () => {
    let promise = new Promise((resolve, reject) => {
      resolve(getLocalItem(REFRESH_TOKEN_KEY))
    })

    if (window.webViewNativeInterface && window.webViewNativeInterface.NATIVE_getRefreshToken) {
      const callbackSuffix = new Date().getTime() + '_' + getRandomInt()
      const successCallbackId = 'NATIVE_getRefreshToken_successCallback_' + callbackSuffix
      const errorCallbackId = 'NATIVE_getRefreshToken_errorCallback_' + callbackSuffix

      const timeout = setTimeout(() => {
        if (window[errorCallbackId]) {
          window[errorCallbackId](new Error(NATIVE_RESPONSE_TIMEOUT_ERROR_MSG))
        }
      }, NATIVE_RESPONSE_TIMEOUT)

      promise = new Promise((resolve, reject) => {
        window[successCallbackId] = resolve
        window[errorCallbackId] = reject
      })
      window.webViewNativeInterface.NATIVE_getRefreshToken(successCallbackId, errorCallbackId)
    }

    return promise
  }

  saveAuthTokens = async (userId, accessToken, refreshToken) => {
    userId = null // 현재로서는 멀티 유저 로그인 기능을 제공하지 않으나, 향후 도입 가능성 존재함.
    let promise = new Promise((resolve, reject) => {
      setLocalItem(ACCESS_TOKEN_KEY, accessToken)
      setLocalItem(REFRESH_TOKEN_KEY, refreshToken)
      resolve()
    })

    if (window.webViewNativeInterface && window.webViewNativeInterface.NATIVE_saveAuthTokens) {
      const callbackSuffix = new Date().getTime() + '_' + getRandomInt()
      const successCallbackId = 'NATIVE_saveAuthTokens_successCallback_' + callbackSuffix
      const errorCallbackId = 'NATIVE_saveAuthTokens_errorCallback_' + callbackSuffix

      const timeout = setTimeout(() => {
        if (window[errorCallbackId]) {
          window[errorCallbackId](new Error(NATIVE_RESPONSE_TIMEOUT_ERROR_MSG))
        }
      }, NATIVE_RESPONSE_TIMEOUT)

      promise = new Promise((resolve, reject) => {
        window[successCallbackId] = resolve
        window[errorCallbackId] = reject
      })
      window.webViewNativeInterface.NATIVE_saveAuthTokens(userId, accessToken, refreshToken, successCallbackId, errorCallbackId)
    }

    return promise
  }

  deleteAuthTokens = async () => {
    let promise = new Promise((resolve, reject) => {
      setLocalItem(ACCESS_TOKEN_KEY)
      setLocalItem(REFRESH_TOKEN_KEY)
      resolve()
    })

    if (window.webViewNativeInterface && window.webViewNativeInterface.NATIVE_deleteAuthTokens) {
      const callbackSuffix = new Date().getTime() + '_' + getRandomInt()
      const successCallbackId = 'NATIVE_deleteAuthTokens_successCallback_' + callbackSuffix
      const errorCallbackId = 'NATIVE_deleteAuthTokens_errorCallback_' + callbackSuffix

      const timeout = setTimeout(() => {
        if (window[errorCallbackId]) {
          window[errorCallbackId](new Error(NATIVE_RESPONSE_TIMEOUT_ERROR_MSG))
        }
      }, NATIVE_RESPONSE_TIMEOUT)

      promise = new Promise((resolve, reject) => {
        window[successCallbackId] = resolve
        window[errorCallbackId] = reject
      })
      window.webViewNativeInterface.NATIVE_deleteAuthTokens(successCallbackId, errorCallbackId)
    }

    return promise
  }

  login = async (type, variables) => {
    await this._logout()
    if (type === 'email') {
      const { success, data, error } = await this.request('post', 'loginEmail', variables.params, { preventAuthRetry: true })
      if (success) {
        const user = data.user
        const accessToken = data.token
        const refreshToken = data.refresh_token
        if (user.is_email_verified) {
          await this.saveAuthTokens(user.id, accessToken, refreshToken)
          this.checkSaveLogin(user.id, refreshToken)
          this.setFcm()
          return true
        } else {
          this.dispatch(setAlert({
            body: `${user.email}은 아직 이메일 인증을 하지 않았습니다. 이메일 인증 링크를 재전송하시겠습니까?`,
            onClick: () => this.resendEmail(user.email, variables.router)
          }))
          return false
        }
      } else {
        if (error.data.code === 'authentication_failed') variables.callback()
        else this.dispatch(setAlert({ body: error.data?.detail || '서버 오류입니다.' }))
        return false
      }
    }
  }

  deleteFcm = async () => {
    // TODO: 본디 FCM 토큰의 만료 처리는 클라이언트 측 처리만으로는 한계가 있음.
    // 때문에 향후 다음 사안들이 고려되어야 함.
    // 1. 서버는 클라이언트 FCM 토큰의 유효성을 확인할 수 있는 도구를 마련해야 함.
    // 2. 클라이언트가 서버에 특정 FCM 토큰 만료 처리를 요청할 시,
    //    해당 FCM 토큰 정보를 서버로 전달하고 서버는 이를 검증하는 절차를 거치도록 해야함 것임.
    // eg) Silent push.
    const fcmToken = getLocalItem('fcmToken')
    const accessToken = await this.getAccessToken()
    if (accessToken && fcmToken) {
      await this.request('delete', 'me', undefined, {
        path: 'fcm',
        preventErrorAlert: true,
        preventRedirectToLogin: true
      })
    }
  }

  _logout = async () => {
    // **주의**: '_logout()' 내 유저 인증을 요하는 API 요청이 있으면(그리하여 refreshTokenError로 이어지는 경우 -  axiosInstance의 interceptor 참조) 무한 루프에 빠질 수 있음. **

    // 렌더링 이벤트를 발생시키지 않는 내부 처리 (유저 세션 리셋)
    setLocalItem('is_save_login_time')
    setLocalItem('sns-signup-user')
    setLocalItem('sirs-order-items')
    setLocalItem('sirs-redirect')
    setLocalItem('sirs-counsel-item')
    setLocalItem('sirs-counsel-list')
    setLocalItem('stop-mobile-app-download-time')
    await this.deleteAuthTokens()
  }

  logout = async (redirectUrl) => {
    try {
      // 오류 상황에 대해서 관대하게 처리.
      await this.deleteFcm()
    } catch (ignored) {}
    await this._logout()
    this.dispatch(setUser())
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }

  completePayment = async ({ imp_uid, merchant_uid, number }) => {
    return new Promise(resolve => {
      setTimeout(async () => {
        const { success } = await window.api.request('post', 'clinics', {
          imp_uid,
          merchant_uid
        }, {
          path: `${number}/payment/complete/`,
          preventErrorAlert: true
        })
        if (success) resolve(true)
        else if (this.paymentLimit > this.paymentTimer) {
          this.paymentTimer += 1
          return resolve(await this.completePayment({ imp_uid, merchant_uid, number }))
        } else {
          resolve(false)
        }
      }, 2000)
    })
  }

  payment = async ({ number, method, success, imp_uid, merchant_uid, router, app_scheme, error_msg }) => {
    if (success) {
      if (method === 'vbank') {
        this.dispatch(setAlert({
          body: '가상계좌 발급이 완료되었습니다.',
          onClick: () => router.replace('/me/reservation?tab=waiting'),
          onClose: () => router.replace('/me/reservation?tab=waiting')
        }))
        return
      }

      this.dispatch(setIsLoading(true))
      const s = await this.completePayment({ imp_uid, merchant_uid, number })
      this.dispatch(setIsLoading(false))
      if (s) {
        TagManager.dataLayer({ dataLayer: { event: 'gtm_event_purchase' } })
        this.dispatch(setAlert({
          body: '결제가 완료되었습니다.',
          onClick: () => router.replace(`/me/reservation/${number}`),
          onClose: () => router.replace(`/me/reservation/${number}`)
        }))
      } else {
        this.dispatch(setAlert({
          body: '결제가 정상적으로 완료되지 않았습니다.',
          onClick: () => router.replace(`/me/reservation/${number}`),
          onClose: () => router.replace(`/me/reservation/${number}`)
        }))
      }
    } else {
      this.dispatch(setAlert({
        body: error_msg || '결제에 실패하였습니다.',
        onClick: () => router.replace(`/me/reservation/${number}`),
        onClose: () => router.replace(`/me/reservation/${number}`)
      }))
    }
  }

  refreshAuthTokens = async () => {
    const { data } = await this.axiosInstance.post(pathSet.refresh, { refresh_token: await this.getRefreshToken() }, { isRefreshAuthTokenRequest: true })
    await this.saveAuthTokens(null, data.token, data.refresh_token)
    return { accessToken: data.token, refreshToken: data.refresh_token }
  }

  request = async (method, pathName, variable, config = {}) => {
    let query = ''
    if (method === 'get' || method === 'delete') {
      query = createParameter(variable)
      variable = null
    }
    const MAX_WAIT_COUNT = 5
    try {
      if (process.env.NODE_ENV === 'development' && process.env.INIT_MSW === 'true') {
        let waitCount = 0
        for (waitCount = 0; waitCount <= MAX_WAIT_COUNT; waitCount++) {
          if (window.isMockInit) {
            break
          }
          await new Promise(resolve => setTimeout(resolve, 1000))
        }
        if (waitCount === MAX_WAIT_COUNT) { throw new Error('MSW INIT FAILED') }
      }

      const configOverriden = config || {}
      Object.assign(configOverriden, {
        method,
        url: config.isPagingWithCursor ? config.path : `${pathSet[pathName]}${config.path || ''}${query}`,
        data: variable || config.data,
        params: config.params
      })

      if (!configOverriden.headers) {
        configOverriden.headers = {}
      }

      if (config.noAuth) {
        configOverriden.headers.authorization = null
      } else {
        const accessToken = await this.getAccessToken()
        if (accessToken) {
          configOverriden.headers.authorization = 'Bearer ' + accessToken
        }
      }

      const response = await this.axiosInstance(configOverriden)

      return { data: response.data, success: true, headers: response.headers }
    } catch (error) {
      if (!error.response) {
        // 서버로 부터 응답(response)을 받지 못한 경우(ex. 네트워크 오류), error 를 그대로 throw 하여 더이상의 진행을 막는다.
        console.error(error)
        throw error
      }

      if (!config.preventErrorAlert) {
        if (this.alertError) {
          this.alertError(error)
        }
      }

      // 서버로 부터 응답 (response)을 받은 경우는 호출부에서 따로 처리하고 있음.
      return { success: false, error: error.response || {} }
    }
  }
}

export default Api
