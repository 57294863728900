import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { spaces } from 'libs/theme'

import { Grid, Text } from 'components/elements'
import { setToast } from 'store/default'
import { heights } from 'libs/items'

let timer = null
let timer2 = null
const Toast = memo(() => {
  const dispatch = useDispatch()
  const { toast } = useSelector(state => state.default)
  const [isOpen, setisOpen] = useState(false)

  useEffect(() => {
    // todo 토스트 메세지가 중복으론 노출됐을때 초기화가 되지 않는다.
    if (!!toast && !isOpen) {
      setisOpen(true)
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        setisOpen(false)
        if (timer2) clearTimeout(timer2)
        if (toast?.callback) toast.callback()
        timer2 = setTimeout(() => dispatch(setToast(undefined)), toast?.time || 400)
        timer2 = null
      }, toast?.time || 1200)
      timer = null
    }
  }, [toast])

  if (!toast) return null

  return (
    <Grid position='fixed' justify='center' justifyContent='center' columns='1fr'
          style={{ bottom: isOpen ? '35px' : '-35px', opacity: isOpen ? '1' : '0', left: 0, right: 0, transition: 'top 0.4s,opacity, 0.3s', zIndex: 10000 }}>
      <Grid width='95%' maxWidth={`${heights.maxWidth - 50}px`}>
        <Grid padding={spaces.component.s4.px} color={toast.color || 'primaryDark'} borderRadius={spaces.component.s3.px}>
          {toast.text}
        </Grid>
      </Grid>
    </Grid>
  )
}, () => {
  return true
})

export default Toast
