import { combineReducers, configureStore } from '@reduxjs/toolkit'

import defaultReducer from 'store/default'
import routineReducer from 'store/routine'

const rootReducer = combineReducers({
  default: defaultReducer,
  routine: routineReducer
})

const initialState = {}

export default configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})
