import React, { memo } from 'react'
import Head from 'next/head'

const Meta = memo(({
  isOverride = false,
  title = '썰즈 sir’s - 남성 메디컬 헬스케어',
  url,
  image = '/images/opengraph.png',
  description = '남자 건강관리 1등은 썰즈! 비대면 진료와 약 배송, 무료 상담과 건강 정보까지 한 눈에. 전문의가 탈모, 여드름, 수면장애까지 케어합니다.'
}) => {
  const addGoogleAnalyticsScript = () => {
    return {
      __html: process.env.GOOGLE_GA_ID ? `
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
         gtag('config', '${process.env.GOOGLE_GA_ID}');
       ` : ''
    }
  }

  const addJsonld = () => ({
    __html: `
    {
      "@context":"http://schema.org",
      "@type":"Organization",
      "name":"Sir’s",
      "url":"${process.env.WEB_URL}",
      "sameAs":[
        "https://www.instagram.com/sirs.kr/",
        "https://www.facebook.com/111080891377031",
        "https://smartstore.naver.com/dearsirs",
        "https://blog.naver.com/sirs_official"
      ]
    } 
  `
  })

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <title key='title'>{title}</title>
        <link rel='icon' href='/favicon.ico' />
        <link key='canonical' rel='canonical' href={url} />
        <meta name='viewport' content='initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width, target-densitydpi=medium-dpi, minimal-ui, viewport-fit=cover' />

        <meta key='description' name='description' content={description} />
        <meta key='keywords' name='keywords' content='비대면진료, 원격진료, 약배송, 약배달, 처방약, 탈모약, 여드름약, 수면유도제, 비뇨기, 남자시술, 써쓰, 써스, 서쓰' />
        <meta name='theme-color' content='#000000' />
        <meta key='og:title' property='og:title' content={title} />
        <meta key='og:description' property='og:description' content={description} />
        <meta key='og:image' property='og:image' content={`${image.indexOf('http') > -1 ? '' : process.env.WEB_URL}${image}`} />
        <meta key='og:url' property='og:url' content={url} />
        <meta key='og:type' property='og:type' content='website' />

        <meta property='fb:app_id' content='838379673882189' />
        <meta property='al:ios:url' content='sirs://' />
        <meta property='al:ios:app_store_id' content='1587599983' />
        <meta property='al:ios:app_name' content='썰즈 - 남성 전문 헬스케어' />

        {
        !isOverride && (
          <>
            <link rel='apple-touch-icon' href={`${process.env.WEB_URL}/images/icon.png`} />
            <link rel='manifest' href={`${process.env.WEB_URL}/manifest.json`} />
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_GA_ID}`} />
            <script dangerouslySetInnerHTML={addGoogleAnalyticsScript()} />
            <script async src='https://www.youtube.com/iframe_api' />
            <meta name='facebook-domain-verification' content='z3spp86tswg1ooufv3hzl3t1ct8nuj' />
            <meta name='naver-site-verification' content='c447a8807bbc75385f3d4457d945744e47d3207d' />
            {/* eslint-disable-next-line @next/next/no-sync-scripts */}
            <noscript>
              <iframe src='https://www.googletagmanager.com/ns.html?id=GTM-W7NT8W7' height='0' width='0' style={{ display: 'none', visibility: 'hidden' }} />
            </noscript>
            <script type='application/ld+json' dangerouslySetInnerHTML={addJsonld()} />
          </>
        )
      }
      </Head>
    </>
  )
}, (p, n) => {
  return true
})

export default Meta
