import { createSlice } from '@reduxjs/toolkit'
import { format, timestamp } from '../libs/time'
// 루틴 메인 - 오늘의 루틴 탭
const todayRoutineMain = {
  orderType: 'TIME',
  currentWeek: {},
  achieveRateList: [],
  weekStartDate: '',
  weekEndDate: ''
}

export const routineSlice = createSlice({
  name: 'routine',
  initialState: {
    selectedDate: timestamp(format(new Date().getTime(), 'YYYY-MM-DD 00:00:00')),
    popularRoutineTagList: [
      { label: '전체', key: '인기_전체' },
      { label: '지구력', key: '인기_지구력' },
      { label: '남성호르몬', key: '인기_남성호르몬' },
      { label: '성기능', key: '인기_성기능' },
      { label: '자세', key: '인기_자세' },
      { label: '다이어트', key: '인기_다이어트' },
      { label: '피부', key: '인기_피부' },
      { label: '탈모', key: '인기_탈모' },
      { label: '두피', key: '인기_두피' },
      { label: '화법', key: '인기_화법' },
      { label: '목소리', key: '인기_목소리' },
      { label: '집중력', key: '인기_집중력' },
      { label: '수면', key: '인기_수면' }
    ],
    routineTagList: [
      { label: '지구력', key: '지구력' },
      { label: '남성호르몬', key: '남성호르몬' },
      { label: '성기능', key: '성기능' },
      { label: '자세', key: '자세' },
      { label: '다이어트', key: '다이어트' },
      { label: '피부', key: '피부' },
      { label: '탈모', key: '탈모' },
      { label: '두피', key: '두피' },
      { label: '화법', key: '화법' },
      { label: '목소리', key: '목소리' },
      { label: '집중력', key: '집중력' },
      { label: '수면', key: '수면' }
    ],
    filteredProgramTagList: [],
    filteredPopularRoutineTagList: [],
    filteredRoutineTagList: [],
    isRoutineLoading: false,
    ...todayRoutineMain
  },
  reducers: {
    setOrderType: (state, action) => {
      state.orderType = action.payload
    },
    setTagList: (state, action) => {
      state.tagList = action.payload
    },
    setCongratulations: (state, action) => {
      state.congratulations = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    setCurrentWeek: (state, action) => {
      state.currentWeek = action.payload
    },
    setFilteredRoutineTagList: (state, action) => {
      state.filteredRoutineTagList = action.payload
    },
    setAchieveRateList: (state, action) => {
      state.achieveRateList = action.payload
    },
    setWeekStartDate: (state, action) => {
      state.weekStartDate = action.payload
    },
    setWeekEndDate: (state, action) => {
      state.weekEndDate = action.payload
    },
    setIsRoutineLoading: (state, action) => {
      state.isRoutineLoading = action.payload
    },
    setFilteredPopularRoutineTagList: (state, action) => {
      state.filteredPopularRoutineTagList = action.payload
    }
  }
})

export const {
  setFilteredRoutineTagList,
  setCongratulations, setSelectedDate,
  setCurrentWeek, setOrderType,
  setAchieveRateList,
  setWeekEndDate, setIsRoutineLoading,
  setWeekStartDate,
  setFilteredPopularRoutineTagList
} = routineSlice.actions

export default routineSlice.reducer
