import moment from 'moment-timezone'
import ko from 'moment/locale/ko.js'

export const getTimezone = () => {
  return moment.tz.guess()
}

const formats = {
  iso: 'YYYY-MM-DDTHH:mm:ssZZ'
}

export const timestamp = (t, timezone = getTimezone()) => {
  if (t === undefined || t === null) return t
  return Number(moment.tz(t, timezone).format('x'))
}

export const getMoment = (timestamp) => {
  return moment(timestamp)
}

export const format = (timestamp, format, timezone = 'Asia/Seoul') => {
  if (timestamp === undefined || timestamp === null) return timestamp
  if (format === 'iso') return moment.tz(timestamp, timezone).format()
  else if (formats[format]) return moment.tz(timestamp, timezone).format(formats[format])
  else return moment.tz(timestamp, timezone).format(format)
}

export const addDate = (date = new Date().getTime(), type, count) => {
  return Number(moment(date).add(count, type).format('x'))
}

export const weekText = [
  '월', '화', '수', '목', '금', '토', '일'
]

export const makeTimeText = timestamp => {
  const formatConditions = [
    { key: 'second', condition: 1000 * 60 },
    { key: 'minute', condition: 1000 * 60 * 60 },
    { key: 'hour', condition: 1000 * 60 * 60 * 24 },
    { key: 'yesterday', condition: 1000 * 60 * 60 * 48 },
    { key: 'day', condition: 1000 * 60 * 60 * 24 * 31 },
    { key: 'month', condition: 1000 * 60 * 60 * 24 * 365 }
  ]
  const formats = {
    second: '방금',
    minute: 'm분 전',
    hour: 'H시간 전',
    yesterday: '어제',
    day: 'D일 전',
    month: 'M월 D일',
    year: 'YYYY.MM.DD'
  }
  if (!timestamp) return ''
  const now = new Date().getTime()
  const finded = formatConditions.find((f) => now - timestamp < f.condition)
  if (finded) return format(timestamp, formats[finded.key])
  return format(timestamp, formats.year)
}

export const calcAge = date => {
  const today = new Date()
  let age = today.getFullYear() - Number(format(date, 'YYYY'))
  const m = today.getMonth() - Number(format(date, 'M'))
  if (m < 0 || (m === 0 && today.getDate() < Number(format(date, 'D')))) age--
  return age
}

export const convertDateToIsoString = (date) => {
  return (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString()
}
