import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setAlert } from 'store/default'
import { spaces, colors } from 'libs/theme'
import { heights } from 'libs/items'

import { Grid, Text } from 'components/elements'
import { Dialog } from 'components/elements/Dialog'
import { Button } from 'components/elements/Buttons'

const Alert = memo(() => {
  const dispatch = useDispatch()
  const { alert } = useSelector(state => state.default)

  const close = () => {
    dispatch(setAlert())
    if (alert.onClose) alert.onClose()
  }
  const click = () => {
    dispatch(setAlert())
    if (alert.onClick) alert.onClick()
  }

  const checkEnter = e => {
    if (e.key === 'Enter') {
      dispatch(setAlert())
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', checkEnter)
    return () => {
      window.removeEventListener('keydown', checkEnter)
    }
  }, [])

  if (!alert) return null

  return (
    <Dialog isOpen zIndex={1000000} close={close}>
      <Grid minWidth='250px' maxWidth={`${heights.maxWidth}px`}>
        <Grid padding={spaces.contents.s1.px} gap={spaces.component.s4.px}>
          {/* {
            alert.title && (
              <Grid columns='1fr' justify='center'>
                <Text variant='subtitle1' color={colors.grey.g800}>{alert.title}</Text>
              </Grid>
            )
          } */}
          {
            typeof alert.title === 'string'
              ? (
                <Grid columns='1fr' justify='center'>
                  <Text variant='subtitle1' color={colors.grey.g800} html={alert.title} />
                </Grid>
                )
              : alert.title
          }
          <Grid justify='center'>
            {
              typeof alert.body === 'string' ? <Text variant='body3' color={colors.grey.g800} align='left' html={alert.body} />
                : alert.body
            }
          </Grid>
        </Grid>
        {
          alert.type === 'confirm' ? (
            <Grid columns='1fr 1px 1fr' borderWidth='1px' borderColor={colors.grey.g300} borderStyle='solid'>
              <Button variant='text' height='52px' width='100%' radius='0' onClick={close}>
                <Text variant='body2' color={alert.cancelColor || colors.grey.g800}>{alert.cancelText || '취소'}</Text>
              </Button>
              <Grid color={colors.grey.g300} width='1px' height='100%' />
              <Button variant='text' height='52px' width='100%' radius='0' onClick={click}>
                <Text variant='body2' color={alert.buttonColor || colors.primary.light}>{alert.buttonText || '확인'}</Text>
              </Button>
            </Grid>
          ) : (
            <Button minwidth='143px' color={colors.primary.light} height='52px' width='100%' radius='0' onClick={click}>
              <Text variant='body2' color={colors.white}>{alert.buttonText || '확인'}</Text>
            </Button>
          )
        }
      </Grid>
    </Dialog>
  )
}, () => {
  return true
})

export default Alert
