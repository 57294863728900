import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { colors } from 'libs/theme'
import { Grid } from 'components/elements'
import { Spinner } from 'components/elements/Spinner'

export const InitLoading = () => {
  return (
    <div className='init-loading' style={{ backgroundColor: colors.primary.dark_new }}>
      <img src='/images/logo-white.png' style={{ width: '80px' }} />
    </div>
  )
}

export const DefaultLoading = () => {
  const { isLoading, breakpoints } = useSelector(state => state.default)
  if (!isLoading) return null
  return <Loading color={breakpoints.sm ? colors.category.relax.light : colors.primary.normal} />
}

const Loading = memo(({ size, color = colors.primary.normal }) => {
  return (
    <Grid position='absolute' align='center' justify='center' style={{ right: 0, left: 0, bottom: 0, top: 0, zIndex: 11 }}>
      <Spinner color={color} size={size} />
    </Grid>
  )
}, () => {
  return true
})

export default Loading
